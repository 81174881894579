import TerminosModal from "./TerminosModal";
import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { validationSchema } from "../utils/validationSchemaForm";
import { iacento } from "../utils/caracteresUTF8";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import MaskedInput from "react-text-mask";
import { celularMask, dineroMask, rutMask } from "../utils/inputMask"
import { cleanDigitos, cleanRut } from "../utils/cleanInputMask"
import configuration from "../Config";

const FormCalculadora = () => {

    const headers = { "Content-Type": "application/json" };

    const initialValues = {
        nombre: '',
        rut: '',
        correo: '',
        celular: '',
        sueldo: '',
        afp: '-1',
        terminosycondiciones: false,
    }

    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => setModalShow(false);
    const handleShow = () => setModalShow(true);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values, { resetForm }) => {
            setIsLoading(true);
            
            const body = {
                nombre: values.nombre,
                email: values.correo,
                rut: cleanRut(values.rut),
                AfpID: values.afp,
                sueldo: parseInt(cleanDigitos(values.sueldo)),
                FonoMovil: cleanDigitos(values.celular)
            };

            axios.post(configuration.API_URL + 'Prospecto/PostProspecto', body, { headers: headers })
            .then((response) => {
                localStorage.setItem("sueldoAnual", response.data.sueldoAnual);
                localStorage.setItem("sessionId", uuidv4());
                localStorage.setItem("rut", cleanRut(values.rut));
                localStorage.setItem("afp", values.afp);

                const body_eventos = {
                    sesion_id: localStorage.getItem("sessionId"),
                    evento_id: 0,
                    result_0: parseInt(response.data.sueldoAnual),
                    result_1: 0,
                    result_2: 0,
                    rut: cleanRut(values.rut)
                };

                resetForm();

                axios.post(configuration.API_URL + 'Evento/PostEvento', body_eventos, { headers: headers })
                    .then((response) => {
                        if (response.status === 200) {
                            window.location.href = "/resultado";
                        }
                    }).catch(e => {
                        console.error(e);
                        setIsLoading(false);
                    });
            }).catch(e => {
                console.error(e);
                setIsLoading(false);
            });
        }}>
            {(formik) => (
                <Form>
                    <div className="form-group input-wrapper">
                        <p>Ingresa tus datos y lo calcularemos para ti:</p>
                        <Field name="nombre">
                            {({ field, meta }) => (
                                <div className="form-group input-wrapper">
                                    <input
                                        {...field}
                                        type="text"
                                        className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                        id="nombre"
                                        name="nombre"
                                        aria-describedby="nombreAyuda"
                                        placeholder="Nombre"
                                        autoComplete="off" />
                                    <label
                                        htmlFor="nombre"
                                        className="control-label">
                                        Nombre
                                    </label>
                                    <small
                                        id="nombreAyuda"
                                        className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                        {meta.touched && meta.error
                                            ? meta.error
                                            : "Indicanos tu nombre y apellido."}
                                    </small>
                                </div>
                            )}
                        </Field>
                        <Field name="rut">
                            {({ field, meta }) => (
                                <div className="form-group input-wrapper">
                                    <MaskedInput
                                        {...field}
                                        mask={rutMask}
                                        type="text"
                                        className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                        id="rut"
                                        name="rut"
                                        aria-describedby="rutAyuda"
                                        placeholder="RUT"
                                        autoComplete="off" />
                                    <label
                                        htmlFor="rut"
                                        className="control-label">
                                        RUT
                                    </label>
                                    <small
                                        id="rutAyuda"
                                        className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                        {meta.touched && meta.error
                                            ? meta.error
                                            : "Ej. 12.345.678-9."}
                                    </small>
                                </div>
                            )}
                        </Field>
                        <Field name="correo">
                            {({ field, meta }) => (
                                <div className="form-group input-wrapper">
                                    <input
                                        {...field}
                                        type="email"
                                        className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                        id="correo"
                                        name="correo"
                                        aria-describedby="correoAyuda"
                                        placeholder="Correo"
                                        autoComplete="off" />
                                    <label
                                        htmlFor="correo"
                                        className="control-label">
                                        Correo
                                    </label>
                                    <small
                                        id="correoAyuda"
                                        className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                        {meta.touched && meta.error
                                            ? meta.error
                                            : "Ej. aumentatusueldo@afpmodelo.cl"}
                                    </small>
                                </div>
                            )}
                        </Field>
                        <Field name="celular">
                            {({ field, meta }) => (
                                <div className="form-group input-wrapper">
                                    <MaskedInput
                                        {...field}
                                        mask={celularMask}
                                        type="text"
                                        className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                        id="celular"
                                        name="celular"
                                        aria-describedby="celularAyuda"
                                        placeholder={`9 ____ ____`}
                                        autoComplete="off" />
                                    <label
                                        htmlFor="celular"
                                        className="control-label">
                                        Celular
                                    </label>
                                    <small
                                        id="celularAyuda"
                                        className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                        {meta.touched && meta.error ? meta.error : "Ej. 9 1112 23XX"}
                                    </small>
                                </div>
                            )}
                        </Field>
                        <Field name="sueldo">
                            {({ field, meta }) => (
                                <div className="form-group input-wrapper">
                                    <MaskedInput
                                        {...field}
                                        type="text"
                                        mask={dineroMask}
                                        className={`form-control form-control-lg ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}
                                        id="sueldo"
                                        name="sueldo"
                                        aria-describedby="sueldoAyuda"
                                        placeholder="Sueldo Líquido"
                                        autoComplete="off" />
                                    <label
                                        htmlFor="sueldo"
                                        className="control-label">
                                        Sueldo Liquido
                                    </label>
                                    <small
                                        id="sueldoAyuda"
                                        className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                        {meta.touched && meta.error ? meta.error : "El sueldo líquido que recibes luego de los descuentos legales."}
                                    </small>
                                </div>
                            )}
                        </Field>
                        <Field name="afp">
                            {({ field, meta }) => (
                                <div className="form-group input-wrapper select">
                                    <label htmlFor="afp">
                                        Indícanos tu AFP actual:
                                        <select
                                            {...field}
                                            className="form-control form-control-lg"
                                            id="afp"
                                            name="afp">
                                            {" "}
                                            <option value="99">Seleccione una AFP</option>
                                            <option value="0">AFP Capital</option>
                                            <option value="1">AFP Cuprum</option>
                                            <option value="2">AFP Habitat</option>
                                            <option value="3">AFP PlanVital</option>
                                            <option value="4">AFP Provida</option>
                                            <option value="6">AFP Uno</option>
                                        </select>
                                    </label>
                                    <small
                                        id="afpAyuda"
                                        className={`form-text ${meta.touched ? (meta.error ? "is-invalid" : "is-valid") : ""}`}>
                                        {meta.touched && meta.error ? meta.error : ""}
                                    </small>
                                </div>
                            )}
                        </Field>
                    </div>
                    <Field name="terminosycondiciones">
                        {({ field, meta }) => (
                            <div className="form-check">
                                <label
                                    className="form-check-label mt-auto"
                                    htmlFor="terminosycondiciones">
                                    <input
                                        type="checkbox"
                                        name="terminosycondiciones"
                                        {...field}
                                        className="form-check-input"
                                        autoComplete="off" />
                                    <small className="terminosycondiciones">
                                        Acepto las{" "}
                                        <a onClick={handleShow}> condiciones del servicio y la pol{iacento}tica de privacidad.</a>
                                    </small>
                                </label>
                                <small
                                    id="terminosAyuda"
                                    className={`form-text ${meta.touched && meta.error ? 'is-invalid' : ''}`}>
                                    {meta.touched && meta.error ? meta.error : ""}
                                </small>
                            </div>
                        )}
                    </Field>
                    <div className="d-flex justify-content-center">
                        <div className="col justify-content-center d-flex">
                            {isLoading
                                ? <>
                                    <button className="btn btn-lg btn-block" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    </button>
                                </>
                                :
                                <button
                                    type="submit"
                                    id="calcular"
                                    className="btn btn-lg btn-block"
                                    disabled={!(formik.isValid && formik.dirty && formik.values.afp !== '-1')}
                                >
                                    Calcular
                                </button>
                            }
                            <TerminosModal show={modalShow} onHide={handleClose} />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default FormCalculadora;