import React from "react";
import FloatingWhatsApp from "react-floating-whatsapp";
import configuration from "../Config";
import avatar from "../assets/svg/chat.svg";

export default function Whatsapp() {
	return (
		<FloatingWhatsApp
			phoneNumber={configuration.WSP_NUM}
			accountName="AFP Modelo"
			height={300}
			avatar={avatar}
			statusMessage="En Línea"
			chatMessage="Hola!! ¿necesitas ayuda?"
			darkMode={true}
			allowClickAway={true}
			allowEsc={true}
			placeholder="Escribe tu mensaje"
			notification={false}
			notificationDelay={30000}
			notificationSound={false}
			styles={{
                bottom: '80px',  // Ajusta la distancia desde la parte inferior
                right: '50px',   // Ajusta la distancia desde la parte derecha
                zIndex: 1000     // Asegura que esté por encima de otros elementos
            }}
		/>
	);
}