import React, {useState, useEffect} from "react";
import mujer from "../assets/svg/resultadoMina.svg";
import check from "../assets/svg/iconCheck.svg";
import rentabilidad from "../assets/svg/iconFinanzas.svg";
import comisiones from "../assets/svg/iconComisiones.svg";
import sac from "../assets/svg/iconServicio.svg";
import aumentarSueldo from "../assets/svg/mujerCerdito.svg";
import flecha from "../assets/svg/arrow-down.svg";
import mujerSAC from "../assets/svg/mujerSAC.svg";
import "../assets/styles/Resultado.css";
import "../utils/validations";
import {sueldoFormateador} from "../utils/validations";
import TablaRentabilidad from "./Rentabilidad";
import Sueldo from "./Sueldo";
import axios from "axios";
import {Helmet} from "react-helmet";
import configuration from "../Config";
import CambiarmeAFP from "../components/CambiateAFP";

export default function Resultado() {
  const [sueldo, setSueldo] = useState("");
  const [tabla, setTabla] = useState("");

  const headers = {"Content-Type": "application/json"};

  let body_eventos = {
    sesion_id: localStorage.getItem("sessionId"),
    evento_id: 0,
    result_0: parseInt(localStorage.getItem("sueldoAnual")),
    result_1: 0,
    result_2: 0,
    rut: localStorage.getItem("rut"),
  };

  function mostrarTabla(props) {
    if (props) {
      return <TablaRentabilidad />;
    } else return <Sueldo />;
  }

  function cambiarme() {
    body_eventos.evento_id = 1;
    axios
      .post(configuration.API_URL + "Evento/PostEvento", body_eventos, {headers: headers})
      .then((response) => {
        if (response.status === 200) {
          window.location.href =
            "https://cambiate.afpmodelo.cl?utm_source=AumentaTuSueldo&utm_medium=referal&utm_campaign=AumentaTuSueldo&utm_content=BotonAccion";
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  function contactarme() {
    body_eventos.evento_id = 2;
    axios
      .post(configuration.API_URL + "Evento/PostEvento", body_eventos, {headers: headers})
      .then((response) => {
        if (response.status === 200) {
          window.location.href = "/solicitud";
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  const rentabilidad_texto =
    "0,58% de comisión mensual, 50% más bajo que el promedio de las otras AFP.";
  const comision_texto = "Servicios digitales que hacen simple y rápidos tus trámites.";
  const atencion_texto =
    "Atención en redes sociales y en sucursales a lo largo de todo Chile.";
  const dudas_texto =
    "Nuestros ejecutivos pueden asesorarte en línea o vía telefónica. Queremos ayudarte a resolver todas tus inquietudes o darte todas las opciones para tu traspaso.";

  useEffect(() => {
    let sueldoAnual = localStorage.getItem("sueldoAnual");
    let exiteTabla = false;
    if (sueldoAnual < 0) {
      exiteTabla = true;
    }
    setSueldo(sueldoAnual);
    setTabla(exiteTabla);
  });

  return (
    <div>
      <Helmet>
        <title>{`Aumenta tu Sueldo en: ${sueldoFormateador(
          sueldo
        )} | Esto Aumenta tu Sueldo al Año | AFP Modelo`}</title>
        <meta
          name="description"
          content="Aumentar mi sueldo líquido, pagando una menor comisión en AFP. Simula tu aumento de sueldo al cambiarte a AFP Modelo."
        />
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <div className="resultado">
        <section>
          <div className="desktop">
            <div className="row w-100 container-padre aumentar">
              <div className="txtDesktop parrafo1">
                <div className="container-title">
                  {mostrarTabla(tabla)}
                  <button
                    type="button"
                    className="btn btn-lg btn-block"
                    onClick={cambiarme}
                    id="traspaso"
                    hidden={tabla}
                  >
                    Quiero cambiarme a Modelo
                  </button>
                </div>
              </div>
              <img src={aumentarSueldo} alt="Aumenta tu sueldo" />
            </div>
            <div className="row w-100 container-padre flecha">
              <img src={flecha} alt="down" />
            </div>
            <div className="row w-100 container-padre">
              <div className="textosFloating">
                <p>Aumenta tu sueldo.</p>
                <p>Sólo 0,58% de comisión</p>
                <p>Cámbiate 100% online.</p>
              </div>
            </div>
            <div className="row w-100 container-padre dudas">
              <img src={mujerSAC} alt="Dudas" />
              <div className="txtDesktop parrafo2">
                <div className="container-title">
                  <h5>¿Aún tienes dudas?</h5>
                  <p>{dudas_texto}</p>
                  <button
                    type="button"
                    className="btn blue btn-lg btn-block"
                    onClick={contactarme}
                    id="ejecutivo"
                  >
                    Quiero que me contacten
                  </button>
                </div>
              </div>
            </div>
            <div className="row w-100 container-padre ofrecemos">
              <div className="col-12 d-flex justify-content-center mt-5">
                <h5>¿Qué te ofrecemos?</h5>
              </div>
              <div className="bloque desktop">
                <div className="card">
                  <div className="card-header beneficio">
                    <img
                      className="icon rentabilidad"
                      src={rentabilidad}
                      alt="rentabilidad"
                    />
                  </div>
                  <div className="card-body">
                    <p>{rentabilidad_texto}</p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header beneficio">
                    <img className="icon comisiones" src={comisiones} alt="comisiones" />
                  </div>
                  <div className="card-body">
                    <p>{comision_texto}</p>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header beneficio">
                    <img className="icon sac" src={sac} alt="atención al cliente" />
                  </div>
                  <div className="card-body">
                    <p>{atencion_texto}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile">
            <div className="row w-100 justify-content-center d-block">
              <div className="col-12 d-flex justify-content-center pt-4">
                <div className="container-title">
                  {mostrarTabla(tabla)}
                  <div className="sueldo">
                    <div className="row">
                      <div className="listado">
                        <div className="beneficio">
                          <img
                            className="icon check"
                            src={check}
                            alt="check"
                            hidden={tabla}
                          />
                          <p>Aumenta tu sueldo.</p>
                        </div>
                        <div className="beneficio">
                          <img className="icon check" src={check} alt="check" />
                          <p>Sólo 0,58% de comisión</p>
                        </div>
                        <div className="beneficio">
                          <img className="icon check" src={check} alt="check" />
                          <p>Cámbiate 100% online.</p>
                        </div>
                        <button
                          type="button"
                          className="btn btn-lg btn-block"
                          onClick={cambiarme}
                          hidden={tabla}
                        >
                          Cambiarse a Modelo
                        </button>
                      </div>
                      <img className="mujer" src={mujer} alt="mujer" />
                    </div>
                  </div>
                  <div className="justify-content-center">
                    <div className="dudas">
                      <h5>¿Aún tienes dudas?</h5>
                      <p>{dudas_texto}</p>
                      <button
                        type="button"
                        className="btn blue btn-lg btn-block"
                        onClick={contactarme}
                      >
                        Solicitar ejecutivo
                      </button>
                    </div>
                  </div>
                  <div className="row mt">
                    <div className="resultado">
                      <CambiarmeAFP />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
