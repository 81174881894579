import React, {useEffect, useState} from "react";

import {sueldoFormateador} from "../utils/validations";
import "../assets/styles/Resultado.css";
export default function Rentabilidad() {
  const [sueldo, setSueldo] = useState("");

  useEffect(() => {    
    let sueldoAnual = localStorage.getItem("sueldoAnual");
    setSueldo(sueldoAnual);
  
  }, []);

  return (
    <div>
      <h5>Tu sueldo varía en</h5>
      <h1 className="green">
        {sueldoFormateador(sueldo) + " "}
        <span> al año.</span>
      </h1>
          </div>
  );
}
