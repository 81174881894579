import React, { useState, useEffect } from "react";
import { sueldoFormateador } from "../utils/validations";
import "../assets/styles/Resultado.css";

export default function Sueldo() {

    const [sueldo, setSueldo] = useState("");
    const [text, setText] = useState("");

    useEffect(() => {
        let sueldoAnual = localStorage.getItem("sueldoAnual");
        let texto = "aumenta";
        if (sueldoAnual < 0) {
            texto = "disminuye";
            sueldoAnual = sueldoAnual * -1;
        }
        setText(texto);
        setSueldo(sueldoAnual);
    });

    return (
        <div className="container-title">
            <h5>Tu sueldo {text} en</h5>
            <h1 className="green">
                {sueldoFormateador(sueldo) + " "}
                <span> al año.</span>
            </h1>
            <p>  Al pagar menos comisión, tu sueldo líquido aumenta. Cámbiate hoy a AFP Modelo y
                aumenta tu sueldo de manera rápida y fácil.
            </p>
        </div>
    );
}