import React from "react";
import Whatsapp from "./Whatsapp"

const Header = () => (
    <header className="navbar sticky-top navbar-dark bg-header-c">
        <div className="align">
            <div className="logo"></div>
        </div>
        <Whatsapp />
    </header>
);

export default Header;
