import React from "react";
import "./assets/styles/index.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Calculadora from "./containers/Calculadora";
import Resultado from "./containers/Resultado";
import Solicitud from "./containers/Solicitud";
import Rentabilidad from "./containers/Rentabilidad";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TagManager from 'react-gtm-module'


const tagManagerArgs = {
    gtmId: 'GTM-PRRQB97'
}

TagManager.initialize(tagManagerArgs)

const App = function () {
    return (
        <>
            <Header />
            <Router>
                <Switch>
                    <Route path="/" exact component={Calculadora} />
                    <Route path="/resultado" exact component={Resultado} />
                    <Route path="/solicitud" exact component={Solicitud} />
                    <Route path="/rentabilidad" exact component={Rentabilidad} />
                </Switch>
            </Router>
            <Footer />
        </>
    );
};

export default App;
