import React from "react";
import "../assets/styles/Calculadora.css";
import ahorro from "../assets/svg/cerdo.svg";
import pareja from "../assets/svg/pareja.svg";
import {Helmet} from "react-helmet";
import FormCalculadora from "../components/FormCalculadora";
import CambiarmeAFP from "../components/CambiateAFP";
export default function Calculadora() {
  localStorage.setItem("sueldoAnual", 0);

  return (
    <div>
      <Helmet>
        <title>Aumenta tu Sueldo | Cámbiate ahora | AFP Modelo</title>
        <meta
          name="description"
          content="Aumenta tu sueldo líquido. Simula tu aumento de sueldo al cambiarte a AFP Modelo."
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="background">
        <section>
          <div className="row w-100 container-padre calculadora">
            <div className="col-12 d-flex justify-content-center mobile">
              <div className="container-title">
                <h4>Conoce cuánto aumenta tu sueldo,
                  cambiándote a <strong>AFP Modelo</strong>
                </h4>
                <br></br>
                <p>
                  Si pagas menos comisión que en tu acutal AFP,
                  <br /> tu sueldo aumenta.
                </p>
                <br></br>
                <br></br>
                <img src={ahorro} height={100} alt="cerditoAhorro" />
              </div>
            </div>
            <div className="col-sm-12 col-md-5 bloque desktop">
              <div className="row justify-content-start ml-4">
                <div className="txtDesktop col-12 w-100">
                  <div className="container-title row">
                    <h1>Conoce cuánto</h1>
                    <h1>Aumenta tu <br/>sueldo,</h1>
                    <h1 className="green">cambiándote a</h1>
                    <h1 className="green">AFP Modelo.</h1>
                    <p>
                      Si pagas menos comisión que en tu actual AFP, tu sueldo aumenta.
                    </p>
                  </div>
                  <div className="w-100 mt-15">
                    <CambiarmeAFP />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-2 bloque desktop medio">
              <img src={pareja} alt="pareja modelo" className="couple-image" />
            </div>
            <div className="col-sm-12  col-md-5 bloque form">
              <div className="card formulario">
                <div className="card-body">
                  <FormCalculadora />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
