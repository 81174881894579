import React from "react";
import contacto from "../assets/svg/contacto.svg";
import "../assets/styles/Resultado.css";
import "../utils/validations";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function Solicitud() {
    function redireccion() {

        const headers = {
            "Content-Type": "application/json"
        };

        const body_eventos = {
            sesion_id: localStorage.getItem("sessionId"),
            evento_id: 3,
            result_0: parseInt(localStorage.getItem("sueldoAnual")),
            result_1: 0,
            result_2: 0,
            rut: localStorage.getItem("rut")
        }

        axios
            .post('Eventos', body_eventos, { headers: headers })
            .then((response) => {
                if (response.status === 200) {
                    window.location.href = "https://www.afpmodelo.cl/";
                }
            })
            .catch(e => {
                console.error(e);
            });
    }

    const dudas_texto =
        "Muchas gracias por utilizar la calculadora de Aumenta Tu Sueldo, un ejecutivo se contactará contigo a la brevedad para entregarte toda la asesoría necesaria.";

    return (
        <div>
            <Helmet>
                <title>Aumenta tu Sueldo | Solicitud de Contacto | AFP Modelo</title>
                <meta name="description" content="Aumenta tu sueldo líquido, pagando una menor comisión de AFP. Simula tu aumento de sueldo al cambiarte a AFP Modelo." />
                <meta name="robots" content="noindex, follow" />
            </Helmet>
            <div className="solicitud">
                <section>
                    <div className="">
                        <div className="row w-100 container-padre dudas">
                            <div className="col-12 d-flex justify-content-center">
                                <div className="container-block">
                                    <img src={contacto} alt="Contacto Ejecutivo" />
                                    <div className="txtDesktop">
                                        <div className="container-title">
                                            <h5>¡Tu solicitud fue enviada con éxito!</h5>
                                            <p>{dudas_texto}</p>
                                            <div className="d-flex justify-content-center">
                                                <button
                                                    type="button"
                                                    className="btn btn-lg btn-block mt-3"
                                                    onClick={redireccion}
                                                    id="webmodelo"
                                                >
                                                    Visitar sitio AFP Modelo
                                                </button>
                                            </div>
                                            <div className="d-flex justify-content-center mt-5">
                                                <Link to="/">
                                                    <a className='volver'>{' '}  Volver atrás</a>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
